import axios from '@axios'

import qs from 'qs'

const prefixPath = '/api/product/stock/type'
export default {

  saveOrUpdate(data) {
    return axios.post(`${prefixPath}/saveOrUpdate`, data)
  },

  getPages(data) {
    return axios.post(`${prefixPath}/getPages`, data)
  },

  delete(id) {
    return axios.post(`${prefixPath}/delete`, qs.stringify({
      id,
    }))
  },
  listAll() {
    return axios.get(`${prefixPath}/listAll`)
  },

}
