<template>
  <div v-if="$can('stock_type_list', 'stock_type')">
    <v-card class="mt-2">
      <v-card-title>
        <v-row>
          <v-col md="12">
            <p class="text-h6">
              出入库类型
            </p>
          </v-col>
          <v-col md="12">
            <!-- 新增出入库类型， 靠右显示 -->
            <v-btn
              v-if="$can('stock_type_save', 'stock_type')"
              style="float: right"
              color="primary"
              @click="editItem()"
            >
              新增出入库类型
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-tabs
          v-model="currentTab"
          style="box-shadow: none !important;"
          @change="reloadTab"
        >
          <v-tab>入库</v-tab>
          <v-tab>出库</v-tab>
        </v-tabs>
        <v-data-table
          :footer-props="{
            itemsPerPageAllText: '全部',
            itemsPerPageText: '每页显示 ',
            showCurrentPage: true
          }"
          class="mt-5"
          disable-filtering
          :headers="getHeader()"
          :items="stockTypes"
          :items-per-page="pages.itemsPerPage"
          :server-items-length="pages.serverItemsLength"
          no-data-text="无数据"
          @pagination="loadPage"
        >
          <!-- action -->
          <template #[`item.actions`]="{ item }">
            <v-btn
              v-if="!item.isSystem && $can('stock_type_delete', 'stock_type')"
              text
              dense
              x-small
              color="primary"
              @click="showDeleteDialog(item)"
            >
              删除
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <!-- <v-card-actions>
        <v-btn
          v-if="currentTab === 0 && $can('product_stock_in', 'stock')"
          color="primary"
          @click="toStock(1)"
        >
          新建入库单
        </v-btn>
        <v-btn
          v-else-if="currentTab === 1 && $can('product_stock_out', 'stock')"
          color="primary"
          @click="toStock(0)"
        >
          新建出库单
        </v-btn>
      </v-card-actions> -->
    </v-card>

    <v-navigation-drawer
      v-model="editDialog"
      temporary
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 750 : '100%'"
      app
    >
      <v-card height="100%">
        <div class="drawer-header d-flex align-center">
          <span class="font-weight-semibold text-base text-h6">{{ editedItem.id ? '编辑' : '添加' }}类型</span>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-5"
            color="primary"
            :disabled="commitFlag"
            :loading="commitFlag"
            @click="handleFormSubmit()"
          >
            保存
          </v-btn>
          <v-btn
            icon
            small
            @click="editDialog = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </div>

        <v-card-text
          style="background-color: #F4F5FA; height: 90%; padding-top: 20px;"
          class="overflow-auto"
        >
          <v-card>
            <v-form
              ref="editForm"
              lazy-validation
              @submit.prevent="handleFormSubmit"
            >
              <v-card-text class="pa-3">
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="name"><span style="color: red">*</span>类型名称：</label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      <v-text-field
                        id="name"
                        v-model="editedItem.name"
                        :rules="rules.required"
                        required
                        counter="25"
                        label="类型名称"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="name"><span style="color: red">*</span>类型：</label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      <v-radio-group
                        v-model="editedItem.type"
                        style="margin-top: -6px"
                        row
                      >
                        <v-radio
                          label="入库"
                          value="IN_WAREHOUSE"
                        ></v-radio>
                        <v-radio
                          label="出库"
                          value="OUT_WAREHOUSE"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-dialog
      v-model="deleteDialog"
      width="300"
    >
      <v-card>
        <v-card-text> 确定删除:<span style="color: red">{{ deleteData.name }}</span>?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteItem()"
          >
            确认删除
          </v-btn>
          <v-btn
            color="normal"
            text
            @click="deleteDialog = false"
          >
            取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiClose,
  mdiLinkVariant,
} from '@mdi/js'
import StockTypeAPi from '@/api/product/stockType'

export default {
  name: 'StockType',
  components: {
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
        mdiLinkVariant,
      },
      editDialog: false,
      deleteDialog: false,
      rules: {
        required: [v => !!v || '该字段不能为空'],
      },
      loadings: {
        submitLoading: false,
        bannerUploaderLoading: false,
      },
      stockTypes: [],
      pages: {
        page: 1,
        itemsPerPage: 15,
        serverItemsLength: 0,
      },
      search: {},
      editedItem: {
        id: undefined,
        name: '',
      },
      defaultItem: {
        id: undefined,
        name: '',
        type: 'IN_WAREHOUSE',
      },
      currentTab: 0,
      tabValue: [
        { name: '入库', value: 'IN_WAREHOUSE' },
        { name: '出库', value: 'OUT_WAREHOUSE' },
      ],
      deleteData: {},
      commitFlag: false,
    }
  },
  methods: {
    getHeader() {
      const baseHeader = [
        { text: '类型名称', value: 'name' },
        { text: '创建人', value: 'creator' },
        { text: '创建时间', value: 'createdTime' },
      ]

      // stockTypes是否有非isSystem的对象
      const hasNotSystem = this.stockTypes.some(item => !item.isSystem)
      if (hasNotSystem && this.$can('stock_type_delete', 'stock_type')) {
        baseHeader.push({ text: '操作', value: 'actions' })
      }

      return baseHeader
    },
    toSearch() {
      this.loadPage({ page: 1, itemsPerPage: this.pages.itemsPerPage })
    },
    loadPage({ page, itemsPerPage }) {
      const searchData = {
        rows: itemsPerPage,
        page,
        type: this.tabValue[this.currentTab].value,
      }
      StockTypeAPi.getPages(searchData)
        .then(response => {
          const { data } = response.data
          this.stockTypes = data.records
          if (data.size === -1) {
            this.pages.serverItemsLength = this.stockTypes.length
          } else {
            this.pages.serverItemsLength = data.total
          }
        })
    },
    editItem(item) {
      if (item) {
        this.editedItem = item
      } else {
        this.editedItem = { ...this.defaultItem }
        this.$nextTick(() => {
          this.$refs.editForm.resetValidation()
        })
      }
      this.commitFlag = false
      this.editDialog = true
    },
    handleFormSubmit() {
      const isFormValid = this.$refs.editForm.validate()
      if (!isFormValid) return

      if (this.commitFlag) {
        return
      }

      this.loadings.submitLoading = true

      const submitData = {
        ...this.editedItem,
      }
      const userInfo = localStorage.getItem('userInfo')
      const user = JSON.parse(userInfo)
      submitData.creator = user.organization
      this.commitFlag = true
      StockTypeAPi.saveOrUpdate(submitData)
        .then(response => {
          this.$toast.success('保存成功')

          this.loadPage(this.pages)
          this.commitFlag = false
          this.editDialog = false
        })
        .finally(() => {
          this.loadings.submitLoading = false
        })
    },
    showDeleteDialog(item) {
      this.deleteData = item
      this.deleteDialog = true
    },
    deleteItem() {
      StockTypeAPi
        .delete(this.deleteData.id)
        .then(() => {
          this.$toast.success('删除成功！')
          this.deleteData = {}
          this.loadPage(this.pages)
          this.deleteDialog = false
        })
    },
    reloadTab() {
      if (this.currentTab === 0) {
        this.defaultItem.type = 'IN_WAREHOUSE'
      } else {
        this.defaultItem.type = 'OUT_WAREHOUSE'
      }
      this.toSearch()
    },
    toStock(model) {
      this.$router.push({ path: '/stock', query: { model } })
    },
  },
}
</script>
